/* eslint-disable @typescript-eslint/naming-convention */
import { AppDispatchType, RootStateType } from 'store'
import { UomGroupType } from 'features/Product/@types/Product'
import {
  SkuRegistrationItemType,
  GeneralDataType,
  GrammationType,
  HandlingType,
  PricingType,
  GrammationSecondarySubmitType,
} from 'features/Product/@types/SkuRegistration'
import {
  getLocationDetailList,
  getAllCluster,
  getKongLocationAll,
} from 'features/Location/services/location'
import { GetKongLocationAllResponseType } from 'features/Location/@types/location'
import { getCategoriesChild, getUomList } from 'features/Product/services/product'
import { getBrandList, getBrandDetailById } from 'features/Product/services/brand'
import {
  getAllConstant,
  getSkuRegistrationList,
  getSkuRegistrationDetail,
  submitProductRegistration,
  cancelSkuRegistration,
  rejectSkuRegistration,
  approveSkuRegistration,
  GetSkuRegistrationListParamsType,
  getSkuRegistrationCreatorList,
} from 'features/Product/services/skuRegistration'
import { getPpnList } from 'utils/api'
import { callErrorMsg } from 'helpers/errorMsg'
import { getAllVendors } from 'features/Enterprise/ReorderList/services/reorderList'
import { toastSuccess, toastFailed } from 'utils/toast'
import { initialStateGrammationForm } from 'features/Product/redux/skuRegistration/initialState'
import {
  SkuRegistrationInitialStateType,
  setSkuRegistrationList,
  setConstant,
  setBrandList,
  setBrandDetail,
  setVendorList,
  setPpnList,
  setSkuRegistrationDetailOnFetchSuccess,
  setUomList,
  setErrorGeneralForm,
  setErrorHandlingForm,
  setErrorGrammationForm,
  setErrorPricingForm,
  resetModalUpdateSku,
  setModalUpdateSku,
  setIsOpenModalCancelSkuRegis,
  setIsOpenModalRejectReason,
  setModalApproveSku,
  resetModalApproveSku,
  BrandListType,
  resetSelectedStatus,
  resetSelectedDateRange,
  setCategories,
  setShouldClearSearchKeyword,
  setSelectedCategoryL1,
  setSelectedCategoryL2,
  setCreatorList,
  setSelectedCreator,
  resetParams,
  setModalSelectLocation,
  setClusterList,
} from './slice'

export const getValue = (value: number | string | null | undefined) =>
  value ? value.toString() : ''
export const valueNumberIsEqualToZero = (value: string) => !!(value && +value === 0)
export const valueNumberIsGreaterThanZero = (value: string) => !!(value && +value > 0)
export const getNumberValueIfOptional = (value: string) => (value ? +value : 0)
export const getStringValueIfOptional = (value: string | undefined) =>
  value && value?.length > 0 ? value : null
export const getObjDataIfOptional = (objData: unknown) => objData || null

type JsonValue = string | number | boolean | null | JsonObject | JsonArray
interface JsonObject {
  [key: string]: JsonValue
}
type JsonArray = JsonValue[]

const removeUnderscoreKeys = (obj: JsonValue): JsonValue => {
  if (Array.isArray(obj)) {
    return obj.map(removeUnderscoreKeys)
  }
  if (obj !== null && typeof obj === 'object') {
    return Object.entries(obj)
      .filter(([key]) => !key.startsWith('_')) // Exclude keys with underscore prefix
      .reduce<JsonObject>((acc, [key, value]) => {
        // eslint-disable-next-line no-param-reassign
        acc[key] = removeUnderscoreKeys(value) // Recurse into the value
        return acc
      }, {})
  }
  return obj
}

export const actGetLocationDetail = () => async (dispatch: AppDispatchType) => {
  try {
    const { data } = await getLocationDetailList()
    dispatch(
      setModalSelectLocation({
        locationDetailList: data.data,
      }),
    )
  } catch (err) {
    callErrorMsg(err)
  }
}

export const actGetLocationListByParams =
  (
    searchName?: string,
    locationType?: string,
    callbackFunction?: (locationList: GetKongLocationAllResponseType['location']) => void,
  ) =>
  async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { modalSelectLocation } = getState().skuRegistration

    const searchingName = searchName || modalSelectLocation.searchingInput
    const selectedLocationType = locationType || modalSelectLocation.selectedLocationType

    try {
      const { data } = await getKongLocationAll({
        params: {
          active: true,
          page: modalSelectLocation.currentPage,
          limit: modalSelectLocation.pageSize,
          ...(searchingName && { name: searchingName }),
          ...(modalSelectLocation.selectedLocationDetailType && {
            location_detail_id: modalSelectLocation.selectedLocationDetailType.id,
          }),
          ...(selectedLocationType.length > 0 && { type: selectedLocationType }),
        },
      })

      if (callbackFunction) {
        callbackFunction(data.data.location)
        return
      }

      dispatch(
        setModalSelectLocation({
          locationList: data.data.location,
        }),
      )
    } catch (err) {
      callErrorMsg(err)
    }
  }
const ceilToTwoDecimalPlaces = (num: number) => Math.ceil(num * 100) / 100

export const actGetBrandDetailById = (id: number) => async (dispatch: AppDispatchType) => {
  try {
    const { data } = await getBrandDetailById(id)
    dispatch(setBrandDetail(data.data))
  } catch (err) {
    callErrorMsg(err)
  }
}

export const actFetchConstant = () => async (dispatch: AppDispatchType) => {
  try {
    const { data } = await getAllConstant()
    dispatch(setConstant(data.data))
  } catch (err) {
    callErrorMsg(err)
  }
}

export const actFetchCategoryChild = () => async (dispatch: AppDispatchType) => {
  try {
    const { data } = await getCategoriesChild()
    dispatch(setCategories({ type: 'L1', listData: data }))
  } catch (err) {
    callErrorMsg(err)
  }
}

export const actGetSkuRegistrationCreatorList =
  (name?: string) => async (dispatch: AppDispatchType) => {
    try {
      const { data } = await getSkuRegistrationCreatorList({
        'pagination.page_index': 1,
        'pagination.page_size': 20,
        'filter.name': name || '',
      })
      dispatch(setCreatorList(data.data))
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const actFetchPpnList = () => async (dispatch: AppDispatchType) => {
  try {
    const {
      data: { data },
    } = await getPpnList({ pageIndex: 1, pageSize: 20 })
    dispatch(setPpnList(data))
  } catch (err) {
    callErrorMsg(err)
  }
}

export const actFetchBrandList = (brandName?: string) => async (dispatch: AppDispatchType) => {
  try {
    const { data } = await getBrandList({
      'pagination.page_size': 20,
      'pagination.page_index': 1,
      sort: 'created_at',
      direction: 'asc',
      name: brandName || '',
    })

    const newList = data.data.map((item) => ({
      ...item,
      label: `${item.brand_id} - ${item.brand_name}`,
    })) as unknown as BrandListType
    dispatch(setBrandList(newList))
  } catch (err) {
    callErrorMsg(err)
  }
}

export const actFetchAllCluster = () => async (dispatch: AppDispatchType) => {
  try {
    const { data } = await getAllCluster()
    dispatch(setClusterList(data.data))
  } catch (err) {
    callErrorMsg(err)
  }
}

const getFilterDataToSubmit = (params: SkuRegistrationInitialStateType['params']) => {
  const newFilterData: GetSkuRegistrationListParamsType = {
    'pagination.page_index': params['pagination.page_index'],
    'pagination.page_size': params['pagination.page_size'],
    'pagination.direction': params['pagination.direction'],
  }

  const listFieldNames = Object.keys(params).filter(
    (fieldname) => !['pagination.page_index', 'pagination.page_size'].includes(fieldname),
  ) as unknown as (keyof typeof params)[]

  return listFieldNames.reduce((acc, fieldname) => {
    if (params[fieldname]) {
      return { ...acc, [fieldname]: params[fieldname] }
    }
    return acc
  }, newFilterData)
}

export const actFetchSkuRegistration =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { params } = getState().skuRegistration
    try {
      const paramsToSubmit = getFilterDataToSubmit(params)
      const { data } = await getSkuRegistrationList(paramsToSubmit)
      dispatch(
        setSkuRegistrationList({
          data: data.data,
          pagination: data.pagination,
        }),
      )
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const actFetchSkuRegistrationDetail =
  (callbackFunction?: (skuRegisDetail: SkuRegistrationItemType) => void) =>
  async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    try {
      const { selectedId } = getState().skuRegistration
      const { data } = await getSkuRegistrationDetail(selectedId)
      dispatch(setSkuRegistrationDetailOnFetchSuccess(data.data))
      if (callbackFunction) callbackFunction(data.data)
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const actFetchAllVendors = (companyName?: string) => async (dispatch: AppDispatchType) => {
  try {
    const { data } = await getAllVendors({
      page_size: 20,
      page_index: 1,
      company_name: companyName || '',
    })
    const listData = data.data.map((item) => ({
      id: item.id,
      company_name: item.company_name,
    }))
    dispatch(setVendorList(listData))
  } catch (err) {
    callErrorMsg(err)
  }
}

export const actFetchUomList = (groupType: UomGroupType) => async (dispatch: AppDispatchType) => {
  try {
    const { data } = await getUomList({
      groups: groupType,
      active: true,
      pageSize: 100,
    })

    const listUom = data.data.unit_of_measurements.content.map((item) => ({
      ...item,
      label: `${item.description} (${item.id})`,
    }))
    dispatch(
      setUomList({
        type: groupType,
        listData: listUom,
      }),
    )
  } catch (err) {
    callErrorMsg(err)
  }
}

export const isGeneralFormValid = (
  brandDetail: SkuRegistrationInitialStateType['brandDetail'],
  generalData: GeneralDataType,
  isAutoGenerateBarcode: boolean,
  status: string,
) => {
  let isValid = true

  const newErrorGeneralDataForm = {
    name: '',
    image_from_vendor_url: '',
    brand: '',
    vendor: '',
    sku_number: '',
    bpom: '',
    sni: '',
    pirt: '',
    mui: '',
  }

  const isStatusDraftAndIsBarcodeRejected =
    status.toUpperCase() === 'DRAFT' && generalData.sku_number_rejected

  const validations = [
    {
      condition: !generalData.name.trim(),
      field: 'name',
      message: 'Nama wajib diisi',
    },
    {
      condition: !generalData.image_from_vendor_url.trim(),
      field: 'image_from_vendor_url',
      message: 'Product image link wajib diisi',
    },
    {
      condition: !generalData.brand?.id,
      field: 'brand',
      message: 'Brand wajib diisi',
    },
    {
      condition: !generalData.vendor?.id,
      field: 'vendor',
      message: 'Vendor wajib dipilih',
    },
    {
      condition:
        (isStatusDraftAndIsBarcodeRejected &&
          brandDetail?.barcode_required &&
          !generalData.sku_number.trim()) ||
        (!brandDetail?.barcode_required &&
          !isAutoGenerateBarcode &&
          !generalData.sku_number.trim()),
      field: 'sku_number',
      message: 'Barcode wajib diisi',
    },
    {
      condition: brandDetail?.bpom_required && !generalData.bpom,
      field: 'bpom',
      message: 'BPOM wajib diisi',
    },
    {
      condition: brandDetail?.sni_required && !generalData.sni,
      field: 'sni',
      message: 'SNI wajib diisi',
    },
    {
      condition: brandDetail?.pirt_required && !generalData.pirt,
      field: 'pirt',
      message: 'PIRT wajib diisi',
    },
    {
      condition: brandDetail?.mui_required && !generalData.mui,
      field: 'mui',
      message: 'MUI wajib diisi',
    },
    {
      condition:
        isStatusDraftAndIsBarcodeRejected &&
        generalData.sku_number &&
        Number.isNaN(generalData.sku_number),
      field: 'sku_number',
      message: 'Barcode wajib angka',
    },
    {
      condition: brandDetail?.bpom_required && generalData.bpom && Number.isNaN(generalData.bpom),
      field: 'bpom',
      message: 'BPOM wajib angka',
    },
    {
      condition: brandDetail?.sni_required && generalData.sni && Number.isNaN(generalData.sni),
      field: 'sni',
      message: 'SNI wajib angka',
    },
    {
      condition: brandDetail?.pirt_required && generalData.pirt && Number.isNaN(generalData.pirt),
      field: 'pirt',
      message: 'PIRT wajib angka',
    },
    {
      condition: brandDetail?.mui_required && generalData.mui && Number.isNaN(generalData.mui),
      field: 'mui',
      message: 'MUI wajib angka',
    },
  ]

  validations.forEach(({ condition, field, message }) => {
    if (condition) {
      isValid = false
      newErrorGeneralDataForm[field as keyof typeof newErrorGeneralDataForm] = message
    }
  })
  return {
    isGeneralDataValid: isValid,
    newErrorGeneralDataForm,
  }
}

export const isFieldRejectedOnGeneralForm = (generalData: GeneralDataType) => {
  const paramsToCheck = [
    'name_rejected',
    'image_from_vendor_url_rejected',
    'brand_rejected',
    'vendor_rejected',
    'sku_number_rejected',
    'bpom_rejected',
    'sni_rejected',
    'pirt_rejected',
    'mui_rejected',
    'ppn_rejected',
    'product_for_testing_rejected',
  ] as const
  return paramsToCheck.some((fieldname) => generalData[fieldname])
}

export const isFieldRejectedOnHandlingForm = (handlingData: HandlingType) => {
  const paramsToCheck = [
    'shelf_rejected',
    'special_handling_rejected',
    'storage_handling_rejected',
  ] as const
  return paramsToCheck.some((fieldname) => handlingData[fieldname])
}

export const isFieldRejectedOnGrammationForm = (grammationData: GrammationType) => {
  const paramsPrimarySecondaryToCheck = [
    'barcode_rejected',
    'uom_rejected',
    'multiplier_rejected',
    'multiplier_range_start_rejected',
    'multiplier_range_end_rejected',
  ] as const
  const paramsToCheck = [
    'weight_rejected',
    'length_rejected',
    'width_rejected',
    'height_rejected',
    'sku_uom_rejected',
    'vendor_per_uom_qty_rejected',
    'vendor_length_rejected',
    'vendor_width_rejected',
    'vendor_height_rejected',
    'net_weight_rejected',
    'gross_weight_rejected',
  ] as const

  const isRejectedPrimarySecondary = paramsPrimarySecondaryToCheck.some(
    (fieldname) => grammationData.primary[fieldname] || grammationData.secondary[fieldname],
  )
  const isRejectedGrammation = paramsToCheck.some((fieldname) => grammationData[fieldname])
  return isRejectedPrimarySecondary || isRejectedGrammation
}

export const isFieldRejectedOnPricingForm = (pricingData: PricingType) => {
  const paramsToCheck = [
    'cogs_rejected',
    'srp_rejected',
    'harga_eceran_tertinggi_rejected',
    'harga_eceran_terendah_rejected',
    'het_docs_agreement_rejected',
    'competitor_price_rejected',
    'competitor_link_rejected',
  ] as const
  return paramsToCheck.some((fieldname) => pricingData[fieldname])
}

export const isHandlingFormValid = (handlingData: HandlingType) => {
  let isValid = true

  const newErrorHandlingDataForm = {
    shelf: '',
    storage_handling: '',
    special_handling: '',
  }

  const validations = [
    {
      condition: !handlingData.shelf,
      field: 'shelf',
      message: 'Shelf wajib dipilih',
    },
    {
      condition: !handlingData.storage_handling.code,
      field: 'storage_handling',
      message: 'Storage handling wajib dipilih',
    },
    {
      condition:
        handlingData.is_special_handling &&
        !handlingData.special_handling.is_fragile &&
        !handlingData.special_handling.is_melting &&
        !handlingData.special_handling.is_rotten &&
        !handlingData.special_handling.is_eggs,
      field: 'special_handling',
      message: 'Special handling wajib dipilih',
    },
  ]

  validations.forEach(({ condition, field, message }) => {
    if (condition) {
      isValid = false
      newErrorHandlingDataForm[field as keyof typeof newErrorHandlingDataForm] = message
    }
  })
  return {
    isHandlingDataValid: isValid,
    newErrorHandlingDataForm,
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const isGrammationFormValid = (grammationData: GrammationType) => {
  let isValid = true
  const newErrorGrammationForm = {
    primary_barcode: '',
    primary_multiplier: '',
    primary_multiplier_range_start: '',
    primary_multiplier_range_end: '',
    primary_uom: '',
    secondary_barcode: '',
    secondary_multiplier: '',
    secondary_multiplier_range_start: '',
    secondary_multiplier_range_end: '',
    secondary_uom: '',
    weight: '',
    length: '',
    width: '',
    height: '',
    sku_uom: '',
    outer_barcode: '',
    vendor_per_uom_qty: '',
    vendor_length: '',
    vendor_width: '',
    vendor_height: '',
    net_weight: '',
    gross_weight: '',
  }

  const validations = [
    {
      condition: !grammationData.primary.barcode,
      field: 'primary_barcode',
      message: 'Primary Selling Unit Barcode wajib diisi',
    },
    {
      condition:
        !grammationData.primary.use_multiplier_range &&
        (!grammationData.primary.multiplier ||
          valueNumberIsEqualToZero(grammationData.primary.multiplier)),
      field: 'primary_multiplier',
      message: 'Primary UoM (Qty) wajib diisi',
    },
    {
      condition:
        grammationData.primary.use_multiplier_range &&
        (!grammationData.primary.multiplier_range_start ||
          valueNumberIsEqualToZero(grammationData.primary.multiplier_range_start)),
      field: 'primary_multiplier_range_start',
      message: 'Primary Qty (Min) wajib diisi',
    },
    {
      condition:
        grammationData.primary.use_multiplier_range &&
        (!grammationData.primary.multiplier_range_end ||
          valueNumberIsEqualToZero(grammationData.primary.multiplier_range_end)),
      field: 'primary_multiplier_range_end',
      message: 'Primary Qty (Max) wajib diisi',
    },
    {
      condition: !grammationData.primary.uom?.id || !grammationData.primary.uom,
      field: 'primary_uom',
      message: 'Primary UoM (Type) wajib dipilih',
    },
    {
      condition:
        !grammationData.secondary.barcode &&
        ((grammationData.secondary.multiplier &&
          valueNumberIsGreaterThanZero(grammationData.secondary.multiplier)) ||
          (grammationData.secondary.multiplier_range_start &&
            valueNumberIsGreaterThanZero(grammationData.secondary.multiplier_range_start)) ||
          (grammationData.secondary.multiplier_range_end &&
            valueNumberIsGreaterThanZero(grammationData.secondary.multiplier_range_end)) ||
          grammationData.secondary.uom?.id),
      field: 'secondary_barcode',
      message: 'Secondary Selling Unit Barcode wajib diisi',
    },
    {
      condition:
        !grammationData.secondary.use_multiplier_range &&
        (!grammationData.secondary.multiplier ||
          valueNumberIsEqualToZero(grammationData.secondary.multiplier)) &&
        (grammationData.secondary.barcode || grammationData.secondary.uom?.id),
      field: 'secondary_multiplier',
      message: 'Secondary UoM (Qty) wajib diisi',
    },
    {
      condition:
        grammationData.secondary.use_multiplier_range &&
        (!grammationData.secondary.multiplier_range_start ||
          valueNumberIsEqualToZero(grammationData.secondary.multiplier_range_start)),
      field: 'secondary_multiplier_range_start',
      message: 'Secondary Qty (Min) wajib diisi',
    },
    {
      condition:
        grammationData.secondary.use_multiplier_range &&
        (!grammationData.secondary.multiplier_range_end ||
          valueNumberIsEqualToZero(grammationData.secondary.multiplier_range_end)),
      field: 'secondary_multiplier_range_end',
      message: 'Secondary Qty (Max) wajib diisi',
    },
    {
      condition:
        (!grammationData.secondary.uom?.id || !grammationData.secondary.uom) &&
        (grammationData.secondary.barcode ||
          valueNumberIsGreaterThanZero(grammationData.secondary.multiplier) ||
          valueNumberIsGreaterThanZero(grammationData.secondary.multiplier_range_start) ||
          valueNumberIsGreaterThanZero(grammationData.secondary.multiplier_range_end)),
      field: 'secondary_uom',
      message: 'Secondary UoM (Type) wajib dipilih',
    },
    {
      condition:
        grammationData.primary.barcode.trim() &&
        grammationData.secondary.barcode.trim() &&
        grammationData.primary.barcode.trim() === grammationData.secondary.barcode.trim(),
      field: 'secondary_barcode',
      message:
        'Secondary Selling Unit Barcode tidak boleh sama dengan Primary Selling Unit Barcode',
    },
    {
      condition:
        grammationData.primary.uom?.id &&
        grammationData.secondary.uom?.id &&
        grammationData.primary.uom?.id === grammationData.secondary.uom?.id,
      field: 'secondary_uom',
      message: 'Secondary UoM (Type) tidak boleh sama dengan Primary UoM (Type)',
    },
    {
      condition:
        grammationData.primary.use_multiplier_range &&
        grammationData.primary.multiplier_range_start &&
        grammationData.primary.multiplier_range_end &&
        !Number.isNaN(grammationData.primary.multiplier_range_start) &&
        !Number.isNaN(grammationData.primary.multiplier_range_end) &&
        +grammationData.primary.multiplier_range_start >
          +grammationData.primary.multiplier_range_end,
      field: 'primary_multiplier_range_start',
      message: 'Primary Qty (Min) wajib lebih besar daripada Primary Qty (Max)',
    },
    {
      condition:
        grammationData.secondary.use_multiplier_range &&
        grammationData.secondary.multiplier_range_start &&
        grammationData.secondary.multiplier_range_end &&
        !Number.isNaN(grammationData.secondary.multiplier_range_start) &&
        !Number.isNaN(grammationData.secondary.multiplier_range_end) &&
        +grammationData.secondary.multiplier_range_start >
          +grammationData.secondary.multiplier_range_end,
      field: 'secondary_multiplier_range_start',
      message: 'Secondary Qty (Min) wajib lebih besar daripada Secondary Qty (Max)',
    },
    {
      condition: !grammationData.weight || valueNumberIsEqualToZero(grammationData.weight),
      field: 'weight',
      message: 'Weight wajib diisi',
    },
    {
      condition: !grammationData.length || valueNumberIsEqualToZero(grammationData.length),
      field: 'length',
      message: 'Length wajib diisi',
    },
    {
      condition: !grammationData.width || valueNumberIsEqualToZero(grammationData.width),
      field: 'width',
      message: 'Width wajib diisi',
    },
    {
      condition: !grammationData.height || valueNumberIsEqualToZero(grammationData.height),
      field: 'height',
      message: 'Height wajib diisi',
    },
    {
      condition: !grammationData.sku_uom?.id || !grammationData.sku_uom,
      field: 'sku_uom',
      message: 'SKU UoM wajib dipilih',
    },
    {
      condition:
        !grammationData.vendor_per_uom_qty ||
        valueNumberIsEqualToZero(grammationData.vendor_per_uom_qty),
      field: 'vendor_per_uom_qty',
      message: 'Qty per Vendor UoM wajib diisi',
    },
    {
      condition:
        !grammationData.vendor_length || valueNumberIsEqualToZero(grammationData.vendor_length),
      field: 'vendor_length',
      message: 'Length (Vendor UoM) wajib diisi',
    },
    {
      condition:
        !grammationData.vendor_width || valueNumberIsEqualToZero(grammationData.vendor_width),
      field: 'vendor_width',
      message: 'Width (Vendor UoM) wajib diisi',
    },
    {
      condition:
        !grammationData.vendor_height || valueNumberIsEqualToZero(grammationData.vendor_height),
      field: 'vendor_height',
      message: 'Height (Vendor UoM) wajib diisi',
    },
    {
      condition: !grammationData.net_weight || valueNumberIsEqualToZero(grammationData.net_weight),
      field: 'net_weight',
      message: 'Net weight wajib diisi',
    },
    {
      condition:
        !grammationData.gross_weight || valueNumberIsEqualToZero(grammationData.gross_weight),
      field: 'gross_weight',
      message: 'Gross weight wajib diisi',
    },
  ]

  validations.forEach(({ condition, field, message }) => {
    if (condition) {
      isValid = false
      newErrorGrammationForm[field as keyof typeof newErrorGrammationForm] = message
    }
  })
  return {
    isGrammationDataValid: isValid,
    newErrorGrammationForm,
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const isPricingFormValid = (pricingData: PricingType) => {
  let isValid = true
  const newErrorPricingForm = {
    cogs: '',
    srp: '',
    harga_eceran_tertinggi: '',
    harga_eceran_terendah: '',
    het_docs_agreement: '',
    competitor_price: '',
    competitor_link: '',
  }
  const isHargaEceranTerendahExist =
    pricingData.harga_eceran_terendah && !Number.isNaN(pricingData.harga_eceran_terendah)
  const isHargaEceranTerendahValid =
    +pricingData.harga_eceran_terendah < +pricingData.srp &&
    +pricingData.harga_eceran_terendah > +pricingData.cogs
  const srpAndCogsExist =
    pricingData.srp &&
    !Number.isNaN(pricingData.srp) &&
    pricingData.cogs &&
    !Number.isNaN(pricingData.cogs)
  const validations = [
    {
      condition: !pricingData.cogs || valueNumberIsEqualToZero(pricingData.cogs),
      field: 'cogs',
      message: 'Purchase Price (COGS) Incl. Tax wajib diisi',
    },
    {
      condition: pricingData.cogs && Number.isNaN(pricingData.cogs),
      field: 'cogs',
      message: 'Purchase Price (COGS) Incl. Tax wajib angka',
    },
    {
      condition: !pricingData.srp || valueNumberIsEqualToZero(pricingData.srp),
      field: 'srp',
      message: 'Suggested Retail Price wajib diisi',
    },
    {
      condition: pricingData.srp && Number.isNaN(pricingData.srp),
      field: 'srp',
      message: 'Suggested Retail Price wajib angka',
    },
    {
      condition: srpAndCogsExist && +pricingData.srp < +pricingData.cogs,
      field: 'srp',
      message:
        'Suggested Retail Price tidak boleh lebih kecil dari Purchase Price (COGS) Incl. Tax',
    },
    {
      condition:
        pricingData.have_het &&
        (!pricingData.harga_eceran_tertinggi ||
          valueNumberIsEqualToZero(pricingData.harga_eceran_tertinggi)),
      field: 'harga_eceran_tertinggi',
      message: 'Harga Eceran Tertinggi wajib diisi',
    },
    {
      condition:
        pricingData.harga_eceran_tertinggi && Number.isNaN(pricingData.harga_eceran_tertinggi),
      field: 'harga_eceran_tertinggi',
      message: 'Harga Eceran Tertinggi wajib angka',
    },
    {
      condition:
        pricingData.harga_eceran_tertinggi &&
        !Number.isNaN(pricingData.harga_eceran_tertinggi) &&
        pricingData.srp &&
        !Number.isNaN(pricingData.srp) &&
        +pricingData.harga_eceran_tertinggi < +pricingData.srp,
      field: 'harga_eceran_tertinggi',
      message: 'Harga Eceran Tertinggi wajib lebih besar dari Suggested Retail Price',
    },
    {
      condition:
        pricingData.have_het &&
        (!pricingData.harga_eceran_terendah ||
          valueNumberIsEqualToZero(pricingData.harga_eceran_terendah)),
      field: 'harga_eceran_terendah',
      message: 'Harga Eceran Terendah wajib diisi',
    },
    {
      condition:
        pricingData.harga_eceran_terendah && Number.isNaN(pricingData.harga_eceran_terendah),
      field: 'harga_eceran_terendah',
      message: 'Harga Eceran Terendah wajib angka',
    },
    {
      condition: srpAndCogsExist && isHargaEceranTerendahExist && !isHargaEceranTerendahValid,
      field: 'harga_eceran_terendah',
      message:
        'Harus lebih kecil dari Suggested Retail Price dan lebih besar dari Purchase Price (COGS) Incl. Tax',
    },
    {
      condition: pricingData.have_het && !pricingData.het_docs_agreement.trim(),
      field: 'het_docs_agreement',
      message: 'HET Docs Agreement wajib diisi',
    },
    {
      condition:
        !pricingData.competitor_price || valueNumberIsEqualToZero(pricingData.competitor_price),
      field: 'competitor_price',
      message: 'Competitor Price wajib diisi',
    },
    {
      condition: !pricingData.competitor_link.trim(),
      field: 'competitor_link',
      message: 'Competitor Link wajib diisi',
    },
    {
      condition:
        !pricingData.competitor_price || valueNumberIsEqualToZero(pricingData.competitor_price),
      field: 'competitor_price',
      message: 'Competitor Price wajib diisi',
    },
    {
      condition: !pricingData.competitor_link.trim(),
      field: 'competitor_link',
      message: 'Competitor Link wajib diisi',
    },
  ]
  validations.forEach(({ condition, field, message }) => {
    if (condition) {
      isValid = false
      newErrorPricingForm[field as keyof typeof newErrorPricingForm] = message
    }
  })
  return {
    isPricingDataValid: isValid,
    newErrorPricingForm,
  }
}

export const getCalculatedVendorUomVolume = (grammationForm: GrammationType) => {
  if (grammationForm.vendor_length && grammationForm.vendor_width && grammationForm.vendor_height) {
    return ceilToTwoDecimalPlaces(
      +grammationForm.vendor_length * +grammationForm.vendor_width * +grammationForm.vendor_height,
    )
  }
  return 0
}

export const getCalculatedVolume = (grammationForm: GrammationType) => {
  if (grammationForm.length && grammationForm.width && grammationForm.height) {
    return ceilToTwoDecimalPlaces(
      +grammationForm.length * +grammationForm.width * +grammationForm.height,
    )
  }
  return 0
}

export const getProposedMargin = (pricingForm: PricingType) => {
  if (pricingForm.srp && pricingForm.cogs)
    return ceilToTwoDecimalPlaces((+pricingForm.srp - +pricingForm.cogs) / +pricingForm.cogs)
  return 0
}

export const getProposedPriceIndex = (pricingForm: PricingType) => {
  if (pricingForm.srp && pricingForm.competitor_price) {
    return ceilToTwoDecimalPlaces((+pricingForm.srp / +pricingForm.competitor_price) * 100)
  }
  return 0
}

const getSecondaryParameter = (grammationForm: GrammationSecondarySubmitType) => {
  const { barcode, uom, multiplier_range_start, multiplier_range_end, multiplier } = grammationForm
  if (
    !barcode?.trim() &&
    (!uom || !uom?.id) &&
    !multiplier_range_start &&
    !multiplier_range_end &&
    !multiplier
  )
    return null
  return grammationForm
}

export const getDataForSubmitGeneralDataUntilPricing = (
  modalUpdateSku: SkuRegistrationInitialStateType['modalUpdateSku'],
) => ({
  general_data: {
    ...modalUpdateSku.generalDataForm,
    sku_number: getStringValueIfOptional(modalUpdateSku.generalDataForm.sku_number),
    sku_number_rejected: false,
    bpom: getStringValueIfOptional(modalUpdateSku.generalDataForm.bpom),
    sni: getStringValueIfOptional(modalUpdateSku.generalDataForm.sni),
    pirt: getStringValueIfOptional(modalUpdateSku.generalDataForm.pirt),
    mui: getStringValueIfOptional(modalUpdateSku.generalDataForm.mui),
  },
  handling: modalUpdateSku.handlingDataForm,
  grammation: {
    ...modalUpdateSku.grammationForm,
    primary: {
      ...modalUpdateSku.grammationForm.primary,
      multiplier: getNumberValueIfOptional(modalUpdateSku.grammationForm.primary.multiplier),
      multiplier_range_start: getNumberValueIfOptional(
        modalUpdateSku.grammationForm.primary.multiplier_range_start,
      ),
      multiplier_range_end: getNumberValueIfOptional(
        modalUpdateSku.grammationForm.primary.multiplier_range_end,
      ),
    },
    secondary: getSecondaryParameter({
      ...modalUpdateSku.grammationForm.secondary,
      barcode: getStringValueIfOptional(modalUpdateSku.grammationForm.secondary.barcode),
      uom: (modalUpdateSku.grammationForm.secondary.uom?.id
        ? modalUpdateSku.grammationForm.secondary.uom
        : null) as GrammationSecondarySubmitType['uom'],
      multiplier: getNumberValueIfOptional(modalUpdateSku.grammationForm.secondary.multiplier),
      multiplier_range_start: getNumberValueIfOptional(
        modalUpdateSku.grammationForm.secondary.multiplier_range_start,
      ),
      multiplier_range_end: getNumberValueIfOptional(
        modalUpdateSku.grammationForm.secondary.multiplier_range_end,
      ),
    }),
    weight: +modalUpdateSku.grammationForm.weight,
    length: +modalUpdateSku.grammationForm.length,
    width: +modalUpdateSku.grammationForm.width,
    height: +modalUpdateSku.grammationForm.height,
    volume: getCalculatedVolume(modalUpdateSku.grammationForm),
    volume_uom: {
      id: 'CCM',
      description: 'Cubic Centimeter',
    },
    weight_uom: {
      id: 'GRM',
      description: 'Grams (Weight)',
    },
    dimension_uom: {
      id: 'CMT',
      description: 'Centimeters',
    },
    vendor_per_uom_qty: +modalUpdateSku.grammationForm.vendor_per_uom_qty,
    vendor_length: +modalUpdateSku.grammationForm.vendor_length,
    vendor_width: +modalUpdateSku.grammationForm.vendor_width,
    vendor_height: +modalUpdateSku.grammationForm.vendor_height,
    vendor_uom: {
      id: 'CMT',
      description: 'Centimeters',
    },
    vendor_volume: getCalculatedVendorUomVolume(modalUpdateSku.grammationForm),
    vendor_volume_uom: {
      id: 'CCM',
      description: 'Cubic Centimeter',
    },
    net_weight: +modalUpdateSku.grammationForm.net_weight,
    gross_weight: +modalUpdateSku.grammationForm.gross_weight,
    weight_grammation_uom: {
      id: 'GRM',
      description: 'Grams (Weight)',
    },
    outer_barcode: '',
    outer_barcode_rejected: false,
  },
  pricing: {
    ...modalUpdateSku.pricingForm,
    cogs: +modalUpdateSku.pricingForm.cogs,
    srp: +modalUpdateSku.pricingForm.srp,
    harga_eceran_tertinggi: +modalUpdateSku.pricingForm.harga_eceran_tertinggi,
    harga_eceran_terendah: +modalUpdateSku.pricingForm.harga_eceran_terendah,
    competitor_price: +modalUpdateSku.pricingForm.competitor_price,
    proposed_margin: getProposedMargin(modalUpdateSku.pricingForm),
    proposed_price_index: getProposedPriceIndex(modalUpdateSku.pricingForm),
  },
})

const actSubmitModalUpdateSku =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { skuRegistrationDetail, modalUpdateSku } = getState().skuRegistration
    const skuRegisDetail = skuRegistrationDetail as SkuRegistrationItemType

    const dataToSubmit = removeUnderscoreKeys({
      ...skuRegisDetail,
      ...getDataForSubmitGeneralDataUntilPricing(modalUpdateSku),
    }) as unknown as SkuRegistrationItemType

    try {
      dispatch(setModalUpdateSku({ isSubmittingData: true }))
      await submitProductRegistration(skuRegisDetail.id as string, dataToSubmit)
      dispatch(resetModalUpdateSku())
      toastSuccess('Berhasil mengupdate data sku registration')
      dispatch(actFetchSkuRegistration())
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setModalUpdateSku({ isSubmittingData: false }))
    }
  }

export const actValidateModalUpdateSkuOnSubmit =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { modalUpdateSku, brandDetail, skuRegistrationDetail } = getState().skuRegistration

    const skuRegisDetail = skuRegistrationDetail as SkuRegistrationItemType
    const { isGeneralDataValid, newErrorGeneralDataForm } = isGeneralFormValid(
      brandDetail,
      modalUpdateSku.generalDataForm,
      modalUpdateSku.isAutoGenerateBarcode,
      skuRegisDetail.status,
    )
    const { isHandlingDataValid, newErrorHandlingDataForm } = isHandlingFormValid(
      modalUpdateSku.handlingDataForm,
    )
    const { isGrammationDataValid, newErrorGrammationForm } = isGrammationFormValid(
      modalUpdateSku.grammationForm,
    )
    const { isPricingDataValid, newErrorPricingForm } = isPricingFormValid(
      modalUpdateSku.pricingForm,
    )

    const isGeneralDataRejected = isFieldRejectedOnGeneralForm(modalUpdateSku.generalDataForm)
    const isHandlingDataRejected = isFieldRejectedOnHandlingForm(modalUpdateSku.handlingDataForm)
    const isGrammationDataRejected = isFieldRejectedOnGrammationForm(modalUpdateSku.grammationForm)
    const isPricingDataRejected = isFieldRejectedOnPricingForm(modalUpdateSku.pricingForm)

    if (
      isGeneralDataRejected ||
      isHandlingDataRejected ||
      isGrammationDataRejected ||
      isPricingDataRejected
    ) {
      toastFailed('Ada field yg masih rejected, silahkan perbaiki terlebih dahulu')
      return
    }

    dispatch(setErrorGeneralForm(newErrorGeneralDataForm))
    dispatch(setErrorHandlingForm(newErrorHandlingDataForm))
    dispatch(setErrorGrammationForm(newErrorGrammationForm))
    dispatch(setErrorPricingForm(newErrorPricingForm))

    if (
      !isGeneralDataValid ||
      !isHandlingDataValid ||
      !isGrammationDataValid ||
      !isPricingDataValid
    ) {
      toastFailed('Ada field yg masih belum sesuai, silahkan perbaiki terlebih dahulu')
      return
    }

    dispatch(actSubmitModalUpdateSku())
  }

export const getInitializationOfSecondary = (
  secondary: Nullable<GrammationSecondarySubmitType>,
) => {
  if (!secondary) return { ...initialStateGrammationForm.secondary }
  return {
    ...secondary,
    barcode: getValue(secondary.barcode),
    uom: secondary.uom?.id
      ? secondary.uom
      : {
          id: '',
          description: '',
        },
    multiplier: getValue(secondary.multiplier),
    multiplier_range_start: getValue(secondary.multiplier_range_start),
    multiplier_range_end: getValue(secondary.multiplier_range_end),
  }
}

export const actInitializeFormGeneralDataUntilPricing =
  (responseData: SkuRegistrationItemType) => (dispatch: AppDispatchType) => {
    const { general_data, handling, grammation, pricing } = responseData
    dispatch(
      setModalUpdateSku({
        generalDataForm: {
          ...general_data,
          sku_number: getValue(general_data.sku_number),
          bpom: getValue(general_data.bpom),
          sni: getValue(general_data.sni),
          pirt: getValue(general_data.pirt),
          mui: getValue(general_data.mui),
        },
        handlingDataForm: {
          ...handling,
          is_special_handling: handling.is_special_handling || false,
          is_special_handling_rejected: handling.is_special_handling_rejected || false,
        },
        grammationForm: {
          ...grammation,
          primary: {
            ...grammation.primary,
            multiplier: getValue(grammation.primary.multiplier),
            multiplier_range_start: getValue(grammation.primary.multiplier_range_start),
            multiplier_range_end: getValue(grammation.primary.multiplier_range_end),
          },
          secondary: getInitializationOfSecondary(grammation.secondary),
          weight: getValue(grammation.weight),
          length: getValue(grammation.length),
          width: getValue(grammation.width),
          height: getValue(grammation.height),
          vendor_per_uom_qty: getValue(grammation.vendor_per_uom_qty),
          vendor_length: getValue(grammation.vendor_length),
          vendor_width: getValue(grammation.vendor_width),
          vendor_height: getValue(grammation.vendor_height),
          net_weight: getValue(grammation.net_weight),
          gross_weight: getValue(grammation.gross_weight),
        },
        pricingForm: {
          ...pricing,
          cogs: getValue(pricing.cogs),
          srp: getValue(pricing.srp),
          harga_eceran_tertinggi: getValue(pricing.harga_eceran_tertinggi),
          harga_eceran_terendah: getValue(pricing.harga_eceran_terendah),
          competitor_price: getValue(pricing.competitor_price),
        },
      }),
    )
  }

export const actCancelSkuRegistration =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    try {
      const { selectedId } = getState().skuRegistration
      await cancelSkuRegistration(selectedId)
      toastSuccess('Berhasil membatalkan sku registration')
      dispatch(setIsOpenModalCancelSkuRegis(false))
      dispatch(actFetchSkuRegistration())
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const actRejectSkuRegistration =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { skuRegistrationDetail } = getState().skuRegistration
    const skuRegisDetail = skuRegistrationDetail as SkuRegistrationItemType
    dispatch(
      setModalApproveSku({
        error: {
          reject_reason: !skuRegisDetail.reject_reason.trim() ? 'Alasan reject wajib diisi' : '',
        },
      }),
    )
    if (!skuRegisDetail.reject_reason.trim()) {
      return
    }

    dispatch(setModalApproveSku({ isSubmittingData: true }))

    try {
      await rejectSkuRegistration(skuRegisDetail.id, skuRegisDetail)
      toastSuccess('Sku Registration berhasil direject')
      dispatch(setIsOpenModalRejectReason(false))
      dispatch(actFetchSkuRegistration())
      dispatch(resetModalApproveSku())
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setModalApproveSku({ isSubmittingData: false }))
    }
  }

export const actApproveSkuRegistration =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { selectedId } = getState().skuRegistration
    dispatch(setModalApproveSku({ isSubmittingData: true }))
    try {
      await approveSkuRegistration(selectedId)
      toastSuccess('Sku Registration berhasil diapprove')
      dispatch(actFetchSkuRegistration())
      dispatch(resetModalApproveSku())
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setModalApproveSku({ isSubmittingData: false }))
    }
  }

export const actResetFilterAndFetchListSkuRegistration =
  () => async (dispatch: AppDispatchType) => {
    dispatch(resetSelectedStatus())
    dispatch(resetSelectedDateRange())
    dispatch(setSelectedCreator(null))
    dispatch(setSelectedCategoryL1(null))
    dispatch(setSelectedCategoryL2(null))
    dispatch(setCategories({ type: 'L2', listData: [] }))
    dispatch(resetParams())
    dispatch(actGetSkuRegistrationCreatorList())
    dispatch(setShouldClearSearchKeyword(true))
  }
